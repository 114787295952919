import * as React from "react";
import {RouteComponentProps} from "@reach/router";
import {graphql, Link, useStaticQuery, withPrefix} from "gatsby";

const ETKL = (props: RouteComponentProps) => {
  const myexamplePdf = useStaticQuery(graphql`
    {
      pdf: file(name: { eq: "etkl-privacy" }) {
        name
        extension
        publicURL
      }
    }
  `)

  return (
    <div style={{margin: "50px"}}>
      <h1>Kaksi Kotia ja TurvaKamu -sovellusten tietosuojaseloste</h1>

      <a href={myexamplePdf.pdf.publicURL}><p>Tarkastele tietosuojaselostetta tästä</p></a>
    </div>
  );
}

export default ETKL;
